<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Liste des factures"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-card
                title="Liste des factures"
                no-body
              >
                Liste des factures
              </b-card>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                >
                  <!-- :options="sortOptions" -->
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="invoices"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(montant)="data">
            {{ data.item.devis.montant.toLocaleString('fr-FR', { maximumFractionDigits: 2 }) +' '+ data.item.devis.devise.libelle }}
          </template>
          <template v-slot:cell(transporteur)="data">
            {{ data.item.devis.transporteur !== null ? data.item.devis.transporteur.lastname + ' ' + data.item.devis.transporteur.firstname : data.item.devis.societe.lastname + ' ' + data.item.devis.societe.firstname }}
          </template>
          <template v-slot:cell(total_ttc)="data">
            {{ (data.item.devis.montant + data.item.devis.montant * 0.18).toLocaleString('fr-FR', { maximumFractionDigits: 2 }) +' '+ data.item.devis.devise.libelle }}
          </template>
          <template #cell(etat)="data">
            <b-badge
              pill
              :variant="`${resolveInvoiceEtatVariant(data.item.etat)}`"
              class="text-capitalize"
            >
              {{ data.item.etat }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Prévisualiser la facture!'"

              variant="primary"
              size="sm"
              pill
              :to="{ name: 'espace-affreteur.detail-facture', params: { code: data.item.code } }"
            >
              Visualiser
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Approuver la facture!'"
              v-b-modal.approuver-facture
              variant="success"
              size="sm"
              pill
              class="mx-2"
              @click="getOffreCode(data.item)"
            >
              Approuver
            </b-button>
          </template>

        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="invoices.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal
        id="approuver-facture"
        ref="my-modal"
        cancel-title="Non"
        ok-title="Oui"
        title="Approbation de facture"
        modal-class="modal-success"
        ok-variant="success"
        button-size="sm"
        cancel-variant="outline-secondary"
        @ok="handleOk"
      >
        <form @submit.prevent="handleSubmitModal">
          <h6>Etes-vous sûr de vouloir approuver cette facture ?</h6>

        </form>
      </b-modal>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ref, onMounted, reactive } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BModal,
  BCardBody,
  BFormInput,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import useInvoices from '@/services/invoice/invoiceService'
// eslint-disable-next-line import/no-cycle
import useOffres from '@/services/affreteur/offresService'
// eslint-disable-next-line import/no-cycle
import useTrackings from '@/services/tracking/trackingService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BModal,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const {
      getInvoicesByAffreteur, loader, invoices,
    } = useInvoices()
    const {
      changerEtatOffre,
    } = useOffres()
    const {
      createOrUpdateTracking,
    } = useTrackings()
    const form = reactive({
      code: '',
      statut: 'Approuvée',
      transporteur_id: '',
      offre_id: '',
      lat: null,
      lng: null,
    })

    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []

    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'code', label: 'N° de Fact', sortable: true },
      { key: 'offre.code', label: 'N° Offre', sortable: true },
      { key: 'etat', label: 'ETAT', sortable: true },
      { key: 'transporteur', label: 'Societe / Transporteur', sortable: true },
      { key: 'total_ttc', label: 'Montant Fact TTC', sortable: true },
      { key: 'created_at', label: 'Date Réception', sortable: true },

      { key: 'actions' },
    ]

    /* eslint-disable global-require */
    const items = invoices.value

    onMounted(async () => {
      await getInvoicesByAffreteur()
      // Set the initial number of items
      totalRows.value = items.length
    })
    const resolveInvoiceEtatVariant = status => {
      if (status === 'PROFORMA') return 'primary'
      if (status === 'EMIS') return 'success'
      return 'primary'
    }

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const getOffreCode = item => {
      form.code = item.offre.code
      form.offre_id = item.offre.id
      form.transporteur_id = item.utilisateur.id
    }

    const approveInvoice = async () => {
      await changerEtatOffre(form.code, { ...form })
      await createOrUpdateTracking({ ...form })
    }

    const formatter = value => value.toUpperCase()

    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      approveInvoice,
      fields,
      items,
      getOffreCode,
      onFiltered,
      resolveInvoiceEtatVariant,
      formatter,
      loader,
      invoices,
    }
  },

  computed: {
    sortOptions() {
      return this.fields.filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },

  },
  methods: {

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.approveInvoice()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  },

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
